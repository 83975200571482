@mixin breakpoint($point) {
    @if $point == mobile {
      @media only screen and (max-width: 600px) {
        @content;
      }
    } 
    @else if $point == tablet {
      @media only screen and (max-width: 1024px) {
        @content;
      }
    }
  }