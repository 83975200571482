:root {
  --primary-font: "Poppins";
  /* colors */
  --black: #000000;
  --blue: #0db3b3;
  --grey: #aaaaaa;
  --red: #da535c;
  --white: #ffffff;
  --border-radius: 6px;
  --grey-one: #e6e7e9;
  --overlay: rgba(0, 0, 0, 0.5);
  /* colors end*/

  /* font sizes */
  --f13: 13px;
  --f14: 14px;
  --f15: 15px;
  --f16: 16px;
  --f17: 17px;
  --f18: 18px;
  --f20: 20px;
  /* font sizes end*/
}
/*Common Buttons*/

.btn-primary {
  background: var(--blue);
  border-radius: 10px;
  color: var(--white);
  font-size: var(--f18);
  font-weight: 400;
  border: none;
  height: 60px;
}
.btn-primary:hover {
  background: var(--blue);
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  background-color: var(--blue);
  border-color: var(--blue);
}
.btn-block {
  width: 100% !important;
}
.btn-primary-sm {
  height: 42px;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  width: 160px;
  border: none;
  border-radius: 6px;
  color: var(--white);
}

.btn-red {
  background: var(--red);
}
.btn-blue {
  background: var(--blue);
}
.btn-grey {
  background: var(--grey);
}

/*Common Buttons*/
.text-primary-new {
  color: var(--blue);
}
.text-red {
  color: var(--red);
}



.pt10 {
  padding-top: 10px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pt40 {
  padding-top: 40px;
}
.pt50 {
  padding-top: 50px;
}
.pt60 {
  padding-top: 60px;
}
.pr10 {
  padding-right: 10px;
}
.pr20 {
  padding-right: 20px;
}
.pr30 {
  padding-right: 30px;
}
.pr40 {
  padding-right: 40px;
}
.pr50 {
  padding-right: 50px;
}
.pl10 {
  padding-left: 10px;
}
.pl20 {
  padding-left: 20px;
}
.pl30 {
  padding-left: 30px;
}
.pl40 {
  padding-left: 40px;
}
.pl50 {
  padding-left: 50px;
}

.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt50 {
  margin-top: 50px;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}
.ml30 {
  margin-left: 30px;
}
.ml40 {
  margin-left: 40px;
}
.ml50 {
  margin-left: 50px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}
.mr40 {
  margin-right: 40px;
}
.mr50 {
  margin-right: 50px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb50 {
  margin-bottom: 50px;
}
.w-100 {
  width: 100% !important;
}

