@import url(./assets/fonts/fonts.css);
@import url(variables.css);
@import "./mixins.scss";

* {
  font-family: var(--primary-font);
}
h1,
h2,
h3,
h4,
h5,
p {
  color: var(--black);
}

.globle-container {
  height: 100%;
  min-height: 100vh;
  border-bottom: 10px solid #ffc538;
}
.shine {
  position: relative;
  overflow: hidden;
}
.shine::before {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  content: "";
  display: block;
  height: 100%;
  left: -75%;
  position: absolute;
  top: 0;
  transform: skewX(-25deg);
  width: 50%;
  z-index: 2;
}
.shine:hover::before,
.shine:focus::before {
  animation: shine 0.85s;
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.border-radius {
  border-radius: var(--border-radius);
}
.component-heading {
  font-weight: 600;
  font-size: var(--f20);
  color: var(--black);
}
.component-para {
  color: var(--black);
  font-size: var(--f15);
  font-weight: 400;
  opacity: 40%;
}
.buttons-gorup {
  justify-content: end;
  @include breakpoint(mobile) {
    justify-content: center;
    width: 100%;
  }
  .btn-primary-sm {
    @include breakpoint(mobile) {
      margin-top: 20px;
      width: calc(50% - 10px);
    }
  }
}
.table-container {
  background: #dbf8f8;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  @include breakpoint(mobile) {
    margin-top: 10px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: -10px;
    tr {
      background: var(--white);
      height: 50px;
      border-radius: 6px;
      border-collapse: collapse;
      overflow: hidden;

      td {
        vertical-align: middle;

        span {
          color: var(--black);
          font-size: 13px;
          font-weight: 400;
        }
        span.store-name {
          padding-right: 30px;
          display: block;
        }
        .description {
          margin: 0;
          width: 100%;
          max-width: 500px;
        }
        .status-result {
          // opacity: 40%;
          cursor: pointer;
        }
        .status-result.active {
          color: var(--blue);
          opacity: 100%;
          font-weight: 500;
          cursor: pointer;
        }
        .edit-btn {
          background: url(./assets/images/edit-icon.png) no-repeat left center;
          color: var(--blue);
          cursor: pointer;
        }
      }
      td:first-child {
        border-radius: 6px 0 0 6px;
        padding-left: 40px;
      }
      td:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
    tr.heading {
      background: var(--blue);
      th {
        vertical-align: middle;
        color: var(--white);
        font-size: 13px;
        font-weight: 500;
        white-space: nowrap;
      }
      th:first-child {
        border-radius: 6px 0 0 6px;
        padding-left: 40px;
      }
      th:last-child {
        border-radius: 0 6px 6px 0;
        position: relative;
      }
    }
  }
  .table-fixed-height{
    height: 438px;
  }
}
.btn.back {
  font-size: var(--f15);
  color: #393939;
  transition: unset;
  border: none;
  background: url(./assets/images/left-arrow.png) no-repeat left center;
}
.main-heading {
  font-size: var(--f20);
  color: var(--black);
  font-weight: 600;
}

.form-control:focus,
.form-select:focus,
.page-link:focus {
  border-color: var(--blue);
  box-shadow: none;
}
.customForm {
  .form-item {
    width: 49%;
    position: relative;
    margin-bottom: 20px;
    @include breakpoint(mobile) {
      width: 100%;
    }
    label {
      font-size: var(--f17);
      font-weight: 500;
      color: var(--black);
      margin-bottom: 5px;
      padding-left: 10px;
    }
    label.form-check-label {
      font-size: var(--f13);
      font-weight: 400;
      color: var(--black);
      margin-bottom: 5px;
      padding-left: 0;
      opacity: 0.7;
    }

    input {
      border: 1px solid #d1d1d1;
      border-radius: 10px;
      height: 60px;
      margin-bottom: 20px;
      position: relative;
      font-size: 17px;
      color: var(--black);
      font-weight: 500;
      font-family: "Helvetica";
      &::placeholder {
        opacity: 0.2;
      }
      &:focus {
        border: 1px solid var(--blue);
      }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      //inside the url select your icon.svg
      content: "";
      background: var(--white) url(./assets/images/date-picker-icon.png)
        no-repeat center;
      height: 25px;
      width: 25px;
    }
    .form-check {
      margin-top: -10px;
      margin-bottom: 0;
      input[type="checkbox"] {
        height: 1em;
        border-radius: 0.25em;
        margin-left: -23px;
      }
      input[type="checkbox"]:checked {
        background-color: var(--blue);
      }
      input[type="checkbox"]:focus {
        box-shadow: none;
      }
    }

    @-moz-document url-prefix() {
      input[type="date"] {
        background: var(--white) url(./assets/images/date-picker-icon.png)
          no-repeat 97% center;
      }
    }

    textarea {
      resize: none;
    }
    select {
      border: 1px solid #d1d1d1;
      border-radius: 10px;
      height: 60px;
      margin-bottom: 20px;
      position: relative;
      background: url(./assets/images/down-arrow.png) no-repeat 95% center;
      color: var(--black);
      font-size: 15px;
      font-weight: 500;
      font-family: "Helvetica";
      &:focus {
        border: 1px solid var(--blue);
      }
    }
    .mat-select {
      border: 1px solid #d1d1d1;
      border-radius: 10px;
      height: 60px;
      margin-bottom: 20px;
      position: relative;
      background: url(./assets/images/down-arrow.png) no-repeat 95% center;
      color: var(--black);
      font-size: 15px;
      font-weight: 500;
      font-family: "Helvetica";
      .mat-select-arrow-wrapper {
        display: none;
      }
      .mat-select-value {
        margin-top: 30px;
      }
    }
  }
  .form-item-no-width {
    position: relative;
    margin-bottom: 20px;
    @include breakpoint(mobile) {
      width: 100%;
    }
    label {
      font-size: var(--f17);
      font-weight: 500;
      color: var(--black);
      margin-bottom: 5px;
      padding-left: 10px;
    }
    label.form-check-label {
      font-size: var(--f13);
      font-weight: 400;
      color: var(--black);
      margin-bottom: 5px;
      padding-left: 0;
      opacity: 0.7;
    }

    input {
      border: 1px solid #d1d1d1;
      border-radius: 10px;
      height: 60px;
      margin-bottom: 20px;
      position: relative;
      font-size: 17px;
      color: var(--black);
      font-weight: 500;
      font-family: "Helvetica";
      &::placeholder {
        opacity: 0.2;
      }
      &:focus {
        border: 1px solid var(--blue);
      }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      //inside the url select your icon.svg
      content: "";
      background: var(--white) url(./assets/images/date-picker-icon.png)
        no-repeat center;
      height: 25px;
      width: 25px;
    }
    .form-check {
      margin-top: -10px;
      margin-bottom: 0;
      input[type="checkbox"] {
        height: 1em;
        border-radius: 0.25em;
        margin-left: -23px;
      }
      input[type="checkbox"]:checked {
        background-color: var(--blue);
      }
      input[type="checkbox"]:focus {
        box-shadow: none;
      }
    }

    @-moz-document url-prefix() {
      input[type="date"] {
        background: var(--white) url(./assets/images/date-picker-icon.png)
          no-repeat 97% center;
      }
    }

    textarea {
      resize: none;
    }
    select {
      border: 1px solid #d1d1d1;
      border-radius: 10px;
      height: 60px;
      margin-bottom: 20px;
      position: relative;
      background: url(./assets/images/down-arrow.png) no-repeat 95% center;
      color: var(--black);
      font-size: 15px;
      font-weight: 500;
      font-family: "Helvetica";
      &:focus {
        border: 1px solid var(--blue);
      }
    }
    .mat-select {
      border: 1px solid #d1d1d1;
      border-radius: 10px;
      height: 60px;
      margin-bottom: 20px;
      position: relative;
      background: url(./assets/images/down-arrow.png) no-repeat 95% center;
      color: var(--black);
      font-size: 15px;
      font-weight: 500;
      font-family: "Helvetica";
      .mat-select-arrow-wrapper {
        display: none;
      }
      .mat-select-value {
        margin-top: 30px;
      }
    }
  }
  .upload-img {
    img {
      height: 294px;
    }
    .file-upload {
      position: absolute;
      top: 50%;
      left: 0%;
      right: 0;
      bottom: 0;
      width: 121px;
      height: 41px;
      margin: 0 auto;
      background: var(--blue) url(./assets/images/upload-btn.png) no-repeat;
      border-radius: 6px;

      input {
        font-size: 0;
        padding: 0;
        margin: 0;
        height: 41px;
        opacity: 0;
      }
    }
  }
  .form-item.is-invalid {
    .error-msg {
      position: absolute;
      right: 0;
      color: var(--red);
      font-size: 15px;
      font-weight: 400;
      right: 5px;
    }
    input {
      border: 1px solid var(--red);
    }
  }
}

.dashboar-container {
  .dashboard-wrapper {
    .left-panel {
      width: 20%;
      background: #0db3b3;
      height: 100vh;
      position: sticky;
      top: 0;
      margin-top: -10px;
      @include breakpoint(mobile) {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
      }
      .logo {
        height: 80px;
        text-align: center;
      }
      .nav-pills {
        button {
          color: #ffffff;
          font-size: 15px;
          height: 23px;
          background: none;
          height: 49px;
          text-align: left;
          opacity: 0.7;
          background: url(./assets/images/right-arrow-white.png) no-repeat right
            center;
          padding-right: 30px;
          .nav-icon {
            margin-right: 10px;
          }
        }
        .nav-link.active {
          opacity: 1;
        }
      }
    }
    .left-panel.menuOpen {
      width: 100%;
      z-index: 10000;
      margin-top: 0;
    }
    .right-panel {
      width: 80%;
      @include breakpoint(mobile) {
        width: 100%;
      }
      .top-bar {
        background: #dbf8f8;
        padding: 30px;
        box-sizing: border-box;
        height: 80px;
        display: flex;
        justify-content: end;
        position: sticky;
        @include breakpoint(mobile) {
          line-height: 60px;
          height: 60px;
          padding: 10px;
          background: #0db3b3;
          flex-direction: row;
          align-content: center;
          justify-content: space-between;
          align-items: center;
        }
        .mobile-logo {
          display: none;
          @include breakpoint(mobile) {
            display: block;
          }
        }
        .logout {
          color: #272727;
          font-size: 14px;
          font-weight: 500;
          background: url(./assets/images/right-arrow.png) no-repeat center
            right;
          padding-right: 20px;
          cursor: pointer;
          margin-right: 12px;
          @include breakpoint(mobile) {
            line-height: 40px;
            color: var(--white);
            background: url(./assets/images/right-arrow-white.png) no-repeat
              center right;
            margin-right: 0;
          }
        }
      }
      .tab-content {
        padding: 40px;
        @include breakpoint(mobile) {
          padding: 10px;
        }
      }
    }
  }
}
.hamburger-menu {
  background: url(./assets/images/menu-icon-new.png) no-repeat center;
  background-size: 100%;
  height: 24px;
  width: 24px;
  border: none;
  display: none;

  @include breakpoint(mobile) {
    display: block;
  }
}
.close-icon {
  display: none;
  position: absolute;
  right: 20px;
  top: 20px;
  @include breakpoint(mobile) {
    display: block;
    width: 20px;
    height: 20px;
  }
}
.cdk-overlay-pane {
  top: 418px !important;
  @include breakpoint(mobile) {
    top: 545px !important;
  }
  .mat-select-search-panel {
    box-shadow: none !important;
    .mat-option[aria-disabled="true"].contains-mat-select-search:not(.custom-height) {
      height: 0;
    }
    .mat-option-text {
      .form-control {
        border: none;
        padding: 0;
        margin: 0;
        .mat-select-search-inner {
          width: 90% !important;
          left: 15px;
          top: -58px;
          border: none;
          .mat-select-search-clear {
            display: none;
          }
        }
        .mat-select-search-input {
          border: none;
          margin: 0;
          padding: 0 !important;
          box-sizing: border-box;
          &:focus {
            border: none;
            outline: none;
            box-shadow: none;
          }
          &::placeholder {
            font-size: 0;
          }
        }
      }
    }
  }
}
.action-btns {
  @include breakpoint(mobile) {
    flex-wrap: wrap;
  }

  .search-bar {
    position: relative;
    @include breakpoint(mobile) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    input {
      height: 42px;
      width: 250px;
      border: 1px solid #d1d1d1;
      border-radius: 6px;
      color: #000000;
      font-size: var(--f15);
      font-weight: 400;
      padding-right: 35px;
      @include breakpoint(mobile) {
        width: 100%;
      }
      &::placeholder {
        opacity: 0.2;
      }
      &:focus {
        border-color: var(--blue);
        box-shadow: none;
      }
    }
    .search-btn {
      background: url(./assets/images/search-icon.png) no-repeat;
      width: 14px;
      height: 14px;
      position: absolute;
      right: 14px;
      top: 14px;
      border: none;
      font-size: 0;
    }
  }
  @include breakpoint(mobile) {
    .btn-primary-sm {
      width: 100%;
    }
  }
}
.btn-template {
  color: var(--blue);
  font-size: var(--f15);
  font-weight: 600;
  text-transform: uppercase;
  background: none;
  border: none;
  text-decoration: underline;
  margin-top: 2px;
  vertical-align: top;
  display: flex;
  justify-content: normal;
  align-items: flex-start;
  margin-right: 50px;
  @include breakpoint(mobile) {
    display: block;
    margin: 0 auto;
    padding: 10px;
  }
}
.custom-pagination {
  .pagination {
    justify-content: end;
    @include breakpoint(mobile) {
      justify-content: center;
    }
    .page-item {
      margin-right: 10px;
      .page-link {
        background: var(--white);
        border-radius: 4px;
        width: 30px;
        height: 30px;
        border: none;
        color: var(--black);
        justify-content: center;
        align-items: center;
        font-size: var(--f13);
        font-weight: 500;
        padding: 0;
        margin: 0;
        text-align: center;
        display: flex;
        cursor: pointer;
      }
      .page-link.active {
        background: var(--blue);
        color: var(--white);
      }
      .page-link.btn-arrow-left {
        background: url(./assets/images/pagination-icon-left.png) no-repeat;
        font-size: 0;
      }
      .page-link.btn-arrow-right {
        background: url(./assets/images/pagination-icon-right.png) no-repeat;
        font-size: 0;
      }
    }
    .page-item.active {
      .page-link {
        background: var(--blue);
        color: var(--white);
      }
    }
  }
}

.custom-pagination {
  .ngx-pagination {
    display: flex;
    justify-content: end;
    padding: 0;
    @include breakpoint(mobile) {
      justify-content: center;
    }
    li {
      margin-right: 10px;
      a {
        background: var(--white);
        border-radius: 4px;
        width: 30px;
        height: 30px;
        border: none;
        color: var(--black);
        justify-content: center;
        align-items: center;
        font-size: var(--f13);
        font-weight: 500;
        padding: 0;
        margin: 0;
        text-align: center;
        display: flex;
        cursor: pointer;
      }
    }
    li.current {
      background: var(--blue);
      color: var(--white);
      height: 30px;
      width: 30px;
      text-align: center;
      font-size: var(--f13);
      font-weight: 500;
      border-radius: 4px;
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: center;
    }
    li.pagination-previous {
      background: url(./assets/images/pagination-icon-left.png) no-repeat;
      font-size: 0;
      height: 30px;
      width: 30px;
      a {
        font-size: 0;
        background: url(./assets/images/pagination-icon-left.png) no-repeat;
        height: 30px;
        width: 30px;
      }
    }
    li.pagination-previous.disabled {
      opacity: 0.5;
    }
    li.pagination-next {
      background: url(./assets/images/pagination-icon-right.png) no-repeat;
      font-size: 0;
      height: 30px;
      width: 30px;
      margin: 0;
      a {
        font-size: 0;
        background: url(./assets/images/pagination-icon-right.png) no-repeat;
        height: 30px;
        width: 30px;
      }
    }
    li.pagination-next.disabled {
      opacity: 0.5;
    }
  }
}
.custom-loader {
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 9999;
  .loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.progress-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlay);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .progress {
    width: 50%;
    @include breakpoint(mobile) {
      width: 80%;
    }
    background: var(--grey-one);
    .progress-bar {
      background-color: var(--blue);
      font-weight: 600;
    }
  }
}

.hyperLink {
  color: #0d6efd !important;
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold !important;
  cursor: pointer;
}

.errorMsg {
  color: #ec0e0e;
}

.error {
  border: 1px solid red !important;
}
